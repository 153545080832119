// firebase-config.js
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
   apiKey: 'AIzaSyDepbqdKo-w6Y09zucpW-3r6bivEbVj5fg',
   authDomain: 'domiko-9b421.firebaseapp.com',
   projectId: 'domiko-9b421',
   storageBucket: 'domiko-9b421.appspot.com',
   messagingSenderId: '150989767803',
   appId: '1:150989767803:web:027873127e43fcc2ef0ede',
};

export const firebaseApp = initializeApp(firebaseConfig);
