import {
   getAuth,
   GoogleAuthProvider,
   getRedirectResult,
   signInWithRedirect,
} from 'firebase/auth';
import { firebaseApp } from '../services/Firebase';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const GoogleLoginPage = () => {
   const [searchParams] = useSearchParams();
   const [loading, setLoading] = useState(true);
   const [error, setError] = useState(null);

   useEffect(() => {
      const auth = getAuth(firebaseApp);
      const provider = new GoogleAuthProvider();

      const handleGoogleSignIn = async () => {
         try {
            // Check URL for Google Auth callback indicators
            const isReturningFromGoogle =
               window.location.search.includes('authuser') ||
               window.location.search.includes('oauth_token');

            if (isReturningFromGoogle) {
               console.log(
                  'Returning from Google, checking redirect result...'
               );
               const result = await getRedirectResult(auth);

               if (result?.user) {
                  const firebaseIdToken = await result.user.getIdToken();
                  const redirectUri = searchParams.get('redirect_uri');

                  if (redirectUri) {
                     window.location.href = `${redirectUri}?success=true&token=${firebaseIdToken}`;
                     return;
                  }
                  setLoading(false);
                  return;
               }
            }

            // Only redirect if we haven't just returned from Google
            if (!isReturningFromGoogle) {
               console.log('Starting new Google Sign-In flow...');
               provider.addScope('email');
               provider.addScope('profile');

               await signInWithRedirect(auth, provider);
            } else {
               // If we returned from Google but got no user, there was probably an error
               //setError('Sign-in was not completed');
               setLoading(false);
            }
         } catch (error) {
            console.error('Google Sign-In error:', error);
            //setError(error.message);
            setLoading(false);
         }
      };

      handleGoogleSignIn();
   }, [searchParams]);

   if (error) {
      return <div>Error: {error}</div>;
   }

   return (
      <div>
         {loading ? (
            <div>Redirecting to Google Sign-In...</div>
         ) : (
            <div>Authentication complete!</div>
         )}
      </div>
   );
};

export default GoogleLoginPage;
